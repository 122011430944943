import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    changeMailing,
    changeUserLogin,
    changeUserPhoto,
    getUserInfo
} from "./userActions";
import {IUser} from "../../../models/User";
import {GetUserPayload} from "../../../api/user";


interface IUserState extends IUser {
    isLoading: boolean
    checkedSession: boolean
}

const initialState: IUserState = {
    isLoading: false,
    login: '',
    my_code: '',
    referral_code: '',
    mailing: false,
    photo: '',
    checkedSession: false
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setPhoto: (state, {payload}: PayloadAction<string>) => {
            state.photo = payload
        }
    },
    extraReducers: {
        [getUserInfo.pending.type]: (state) => {
            state.isLoading = true
        },
        [getUserInfo.fulfilled.type]: (state, {payload}: PayloadAction<GetUserPayload>) => {
            state.isLoading = false
            state.login = payload.login
            state.my_code = payload.my_code
            state.referral_code = payload.referral_code
            state.mailing = payload.mailing
            state.photo = payload.photo
        },
        [changeUserLogin.pending.type]: (state) => {
            state.isLoading = true
        },
        [changeUserLogin.fulfilled.type]: (state, {payload}: PayloadAction<string>) => {
            state.isLoading = false
            state.login = payload
        },
        [changeMailing.pending.type]: (state) => {
            state.isLoading = true
        },
        [changeMailing.fulfilled.type]: (state) => {
            state.isLoading = false
            state.mailing = !state.mailing
        },
        [changeUserPhoto.pending.type]: (state) => {
            state.isLoading = true
        },
        [changeUserPhoto.fulfilled.type]: (state, {payload}: PayloadAction<any>) => {
            state.photo = URL.createObjectURL(payload)
            state.isLoading = false
        }
    }
})

export const userActions = userSlice.actions
export default userSlice.reducer
