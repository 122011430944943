import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import cls from './Profile.module.scss'
import {useTranslation} from "react-i18next";
import {useActions} from "../../../../../hooks/useActions";
import {useAppSelector} from "../../../../../hooks/redux";

interface ProfileProps {
    theme: string
}

const Profile: FC<ProfileProps> = ({theme}) => {
    const {changeUserLogin, changeUserPhoto} = useActions()
    const {user_session} = useAppSelector(state => state.authSlice)
    const {photo, login, isLoading} = useAppSelector(state => state.userSlice)
    const [newLogin, setNewLogin] = useState('')
    const [img, setImg] = useState('')
    const [isEdit, setIsEdit] = useState(false)

    useEffect(() => {
        if (photo !== 'no photo' && !photo.includes('http')) return setImg(`data:image/png;base64,${photo}`)
        else if (photo !== 'no photo') return setImg(photo)
        setImg(require(`../../../../../assets/images/svg/${theme}/profile.svg`))
    }, [photo])

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        // @ts-ignore
        const file = e.currentTarget.files[0]
        changeUserPhoto({
            image: file,
            session: user_session
        })
    }

    const handleSetLogin = () => {
        changeUserLogin({
            user_session,
            new_login: newLogin
        })
        setIsEdit(false)
        setNewLogin('')
    }

    const {t} = useTranslation('profile')
    return (
        <div className={`${cls.profile} ${cls[theme]}`}>
            <div className={cls.change}>
                <div className={cls.image}>
                    {/*<h4>{t('change_image')}</h4>*/}
                    <div className={cls.input}>
                        <img
                            src={
                                isLoading
                                    ? require('../../../../../assets/images/svg/loading.svg').default
                                    : img
                            }
                            alt="Profile Image"
                        />
                        <input
                            accept='image/*'
                            onChange={handleChange}
                            type="file"
                        />
                    </div>
                </div>

                <div className={cls.login}>
                    <h4>{t('change_login')}</h4>
                    <div
                        onClick={() => setIsEdit(prev => !prev)}
                        className={`${cls.name} ${cls[theme]}`}
                    >
                        <h3>{login}</h3>
                        <img src={require(`../../../../../assets/images/svg/${theme}/edit.svg`)} alt="Edit"/>
                    </div>
                    {
                        isEdit &&
                        <div className={cls.input}>
                            <input
                                className={cls[theme]}
                                placeholder={t('type_login')}
                                type="text"
                                value={newLogin}
                                onChange={e => setNewLogin(e.currentTarget.value)}
                            />
                            <button
                                onClick={handleSetLogin}
                                disabled={newLogin === ''}
                                className={`${newLogin === '' && cls.disabled}`}
                            >
                                {t('confirm')}
                            </button>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default Profile;
